html {
    scroll-behavior: smooth;
  }
  html, body {
    overflow-x: hidden;
  }
  
  .snap-x {
    scroll-snap-type: x mandatory;
  }
  
  .snap-start {
    scroll-snap-align: start;
  }
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;
